<template>
  <v-container class="py-10">
    <v-dialog v-model="waitingOxxo" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text class="text-center">
          Generando voucher
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" lg="8">
        <p v-if="checkoutError" style="color: red;">
          ERROR: Por favor revisa los datos de compra
        </p>
        <!---------------- Aqui empieza el componente stepper ------------------------->
        <v-stepper v-model="e7" vertical>
          <v-stepper-step :complete="e7 > 1" step="1">
            <span>
              Método de Envío:
            </span>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-btn
              v-for="envio in tipoEnvio"
              :key="envio.id + '-envio'"
              class="mx-4 my-2"
              @click="calcularEnvioCarrito(envio.id)"
              :disabled="!validadorPesoCarrito(envio.id)"
              :style="
                envioSeleccionado === envio.id
                  ? 'background-color: #FF3700; color: white;'
                  : ''
              "
              depressed
            >
              {{ envio.dato }}
            </v-btn>
            <br /> <br>
            <v-btn
              color="secondary"
              @click="e7 = 2"
              :disabled="!envioSeleccionado"
            >
              Continuar
            </v-btn>
          </v-stepper-content>
          <!------------------- Aqui empieza los datos personales ----------------------------->

          <v-stepper-step :complete="e7 > 2" step="2">
            <div>
              Datos Personales:
            </div>
          </v-stepper-step>
          <v-stepper-content step="2">
            <datos-personales 
            ref="personalDataValidation"
            ></datos-personales>
            <br />
            <v-btn  color="secondary"
            id="btn"
            @click="e7 = 3" >
              Continuar
            </v-btn>
            <v-btn text @click="e7 = 1">
              Anterior
            </v-btn>
          </v-stepper-content>
          <!------------------- Aqui terminan los datos personales ----------------------------->

          <!------------------- Aqui Empiezan el metodo de pago ----------------------------->
          <v-stepper-step :complete="e7 > 3" step="3">
            <div>
              <span v-if="paymentMethodIsSelected">
                Método de pago seleccionado:
                <strong>
                  {{ paymentMethodText }}
                </strong>
              </span>

              <span v-else>Elige un método de pago</span>
              <v-icon v-if="!paymentMethodIsSelected" right color="red">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="paymentMethodIsSelected" right color="success">
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-list>
              <v-list-item-group
                v-model="metodoPagoSeleccionado"
                color="primary"
              >
                <v-list-item
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.id"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="paymentMethod.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div v-if="metodoPagoSeleccionado === 0">
              <tarjetas-pago
                :selectedCard="true"
                @tarjetaSeleccionada="tarjetaSeleccionada = $event"
                @tarjetaPredeterminada="tarjetaSeleccionada = $event"
              />
            </div>
            <br />
            <v-btn
              :disabled="!paymentMethodIsSelected"
              color="secondary"
              @click="e7 = 4"
            >
              Continuar
            </v-btn>
            <v-btn text @click="e7 = 2">
              Anterior
            </v-btn>
          </v-stepper-content>
          <!------------------- Aqui termina el método de pago ----------------------------->
          <!------------------- Aqui empieza la sección de dirección ----------------------------->
          <v-stepper-step :complete="e7 > 4" step="4">
            <div>
              <span v-if="direccionSeleccionada">
                Dirección Seleccionada:
                <strong>
                  {{ direccionSeleccionada.calle }}
                </strong>
              </span>
              <span v-else>Elige una dirección</span>
              <v-icon v-if="!direccionSeleccionada" right color="red">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="direccionSeleccionada" right color="success">
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
          </v-stepper-step>

          <v-stepper-content step="4">
            <direcciones
              :selectedAddress="true"
              @dirSeleccionada="direccionSeleccionada = $event"
              @dirPredeterminada="direccionSeleccionada = $event"
            ></direcciones>
            <br />
            <v-btn
              :disabled="!direccionSeleccionada"
              color="secondary"
              @click="e7 = 5"
            >
              Continuar
            </v-btn>
            <v-btn text @click="e7 = 3">
              Anterior
            </v-btn>
          </v-stepper-content>
          <!------------------- Aqui Termina la sección de dirección ----------------------------->
          <!------------------- Aqui empieza la sección de facturación ----------------------------->
          <v-stepper-step :complete="e7 > 7" step="5">
            <div>
              <span v-if="facturacionSeleccionada">
                datos de Facturación Seleccionados:
                <strong>
                  {{ facturacionSeleccionada.tax_id }}
                </strong>
              </span>
              <span v-else>Elige tus Datos de Facturación</span>
              <v-icon v-if="!facturacionSeleccionada" right color="red">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="facturacionSeleccionada" right color="success">
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
          </v-stepper-step>
          <v-stepper-content step="5">
            <fiscales
              :selectedFiscal="true"
              @fiscalSeleccionado="facturacionSeleccionada = $event"
              @fiscalPredeterminada="facturacionSeleccionada = $event"
            ></fiscales>
            <br>
            <v-btn text @click="e7 = 4">
              Anterior
            </v-btn>
          </v-stepper-content>
        </v-stepper>
        <!------------------- Aqui termina la sección de facturación ----------------------------->
      </v-col>

      <v-col cols="12" lg="4">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Tu orden:
            </v-expansion-panel-header>
            <v-expansion-panel-content class="py-4">
              <div v-if="customerDiscount">
                <div v-for="producto in cart" :key="producto.id">
                  {{ producto.producto.nombre_producto }} x {{ producto.qty }} -
                  $ {{ producto.producto.precio }}<br />
                  <small :style="'color: green;'">
                    Se ha aplicado el descuento:
                    {{ customerDiscount.nombre_descuento }} ({{
                      customerDiscount.porcentaje_descuento * 100
                    }}%).<br />
                  </small>
                </div>
              </div>
              <div v-else>
                <div v-for="producto in cart" :key="producto.id">
                  {{ producto.producto.nombre_producto }} x {{ producto.qty }} -
                  $ {{ producto.producto.precio }}<br />
                  <div v-for="desc in producto.descuento" :key="desc.id">
                    <small
                      v-if="producto.qty >= desc.cantidad_compra_minima"
                      :style="
                        producto.qty >= desc.cantidad_compra_minima
                          ? 'color: green;'
                          : ''
                      "
                    >
                      Se ha aplicado el descuento:
                      {{ desc.nombre_descuento }} ({{
                        desc.porcentaje_descuento * 100
                      }}%).<br />
                    </small>
                    <small
                      v-if="producto.qty < desc.cantidad_compra_minima"
                      :style="
                        producto.qty < desc.cantidad_compra_minima
                          ? 'color: red;'
                          : ''
                      "
                    >
                      Para obtener el descuento {{ desc.nombre_descuento }} ({{
                        desc.porcentaje_descuento * 100
                      }}%) debes comprar mínimo
                      {{ desc.cantidad_compra_minima }} "unidades".<br />
                    </small>
                  </div>
                  <v-divider class="my-4"></v-divider>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>nombre</th>
                <th>cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sub Total:</td>
                <td>
                  {{ finalCartData.subtotal | twoDecimals | commaThousand }}
                </td>
              </tr>
              <tr>
                <td>Impuestos:</td>
                <td>
                  {{
                    finalCartData.totalImpuestos | twoDecimals | commaThousand
                  }}
                </td>
              </tr>
              <tr>
                <td>Envío:</td>
                <td>{{ costoEnvioCalculado | twoDecimals | commaThousand }}</td>
              </tr>
              <tr>
                <td>Descuento:</td>
                <td>
                  {{
                    finalCartData.totalDescuento | twoDecimals | commaThousand
                  }}
                </td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>{{ precioFinal | twoDecimals | commaThousand }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          class="mx-auto"
          @click="enviarCarrito"
          :disabled="
            !direccionSeleccionada ||
              !facturacionSeleccionada ||
              !envioSeleccionado ||
              !paymentMethodIsSelected
          "
          color="primary"
          block
        > 
          {{ metodoPagoSeleccionado === 1 ? "Generar voucher oxxo" : "Pagar" }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { ApiAuth } from "@/api_utils/axios-base";
import { tFilters } from "@/mixins/filters.js";
import DatosPersonales from "@/pages/Cuenta/DatosPersonales.vue";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "@/api_utils/config";

export default {
  metaInfo: {
    title: 'Checkout'
  },
  mixins: [tFilters],
  data() {
    return {
      e7: 1,
      stripe: {},
      waitingOxxo: false,
      direcciones: null,
      datosFacturacion: null,
      tipoEnvio: null,
      tabuladorEnvios: null,
      envioCalculado: null,
      direccionSeleccionada: "",
      facturacionSeleccionada: null,
      envioSeleccionado: null,
      btnSelected: 0,
      metodoPagoSeleccionado: null,
      tarjetaSeleccionada: null,
      checkoutError: false,
      nombreDescuentos: "",
      selectedItem: 0,
      paymentMethods: [
        { id: 1, text: "Pago con tarjeta crédito/débito" },
        { id: 2, text: "Pago en efectivo OXXO" },
      ],
      checkoutPreferences: {},
    };
  },
  components: {
    Direcciones: () => import("@/pages/Cuenta/Direcciones.vue"),
    Fiscales: () => import("@/pages/Cuenta/Datos.vue"),
    DatosPersonales,
    TarjetasPago: () => import("@/pages/Cuenta/Cliente/TarjetasPagos.vue"),
  },
  computed: {
    ...mapState("usuario", ["userData", "customerDiscount"]),
    ...mapState("cart", ["cart", "distanciaKm"]),
    ...mapState("products", ["distributorId"]),
    ...mapGetters("usuario", ["userFullname"]),
    finalCartData() {
      return this.calcularDatosFinalesCarrito(this.cart);
    },
    costoEnvioCalculado() {
      return this.envioCalculado || 0;
    },
    precioFinal() {
      return (
        this.costoEnvioCalculado +
        this.finalCartData.subtotal +
        this.finalCartData.totalImpuestos
      );
    },
    paymentMethodText() {
      switch (this.metodoPagoSeleccionado) {
        case 0:
          return ` * ${this.cardLast4}`;
        case 1:
          return this.paymentMethods[1].text;
        default:
          return "";
      }
    },
    paymentMethodIsSelected() {
      return this.metodoPagoSeleccionado !== null &&
        this.metodoPagoSeleccionado !== undefined
        ? true
        : false;
    },
    cardLast4() {
      return this.tarjetaSeleccionada ? this.tarjetaSeleccionada.last4 : "";
    },
    cardId() {
      return this.tarjetaSeleccionada ? this.tarjetaSeleccionada.idCard : null;
    },
    paymentType() {
      switch (this.metodoPagoSeleccionado) {
        case 0:
          return "card";
        case 1:
          return "oxxo";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions("products", ["fetchProducts"]),
    validadorPesoCarrito(envioId) {
      let pesoTotalCarrito = this.finalCartData.totalPesoVol;
      let distanciaClienteSucursal = this.distanciaKm;
      let envioElegido = this.tabuladorEnvios.find(
        (tabulador) =>
          tabulador.tipo_envio.id === envioId &&
          pesoTotalCarrito >= tabulador.volumetrico_inferior &&
          pesoTotalCarrito <= tabulador.volumetrico_superior &&
          distanciaClienteSucursal <= tabulador.rango_en_km
      );
      return envioElegido ? true : false;
    },
    solicitudDatosPersonales(){
      this.$ref.DatosPersonales.validarForm();
    },
    async enviarCarrito() {
      try {
        this.$store.commit("setLoading", true);
        let carroUsuario = {
          direccionUsuario: this.direccionSeleccionada.id,
          facturacionUsuario: this.facturacionSeleccionada.id,
          usuario: this.userData.id,
          tipoEnvio: this.envioSeleccionado,
          total: this.precioFinal,
          subtotal: this.finalCartData.subtotal,
          totalImpuestos: this.finalCartData.totalImpuestos,
          descuento: this.finalCartData.totalDescuento,
          costoEnvio: this.costoEnvioCalculado,
          productosEnCarrito: this.finalCartData.countProds,
          id_card: this.cardId,
          tipo_pago: this.paymentType,
        };
        let carritoFinal = {
          ...this.cart,
          ...carroUsuario,
        };

        let response = await ApiAuth.post(
          "/checkout/crear-orden",
          carritoFinal
        );
        const responseData = await response.data;
        // console.log("order response", responseData);
        if (responseData.error) {
          this.checkoutError = true;
          return;
        }
        if (this.paymentType === "oxxo") {
          this.checkoutPreferences.shippingSelected = this.envioSeleccionado;
          this.checkoutPreferences.paymentMethodSelected = this.metodoPagoSeleccionado;

          localStorage.setItem(
            "checkoutPreferences",
            JSON.stringify(this.checkoutPreferences)
          );

          if (responseData.respuesta_pago.client_secret) {
            this.stripe
              .confirmOxxoPayment(responseData.respuesta_pago.client_secret, {
                payment_method: {
                  billing_details: {
                    name: this.userFullname,
                    email: this.userData.email,
                  },
                },
              })
              .then((result) => {
                this.waitingOxxo = false;
                if (result.error) {
                  // console.log("Ocurrio un error");
                  this.checkoutError = true;
                  localStorage.removeItem("checkoutPreferences");
                } else {
                  // console.log("Todo bien, se imprimio el voucher");
                  localStorage.removeItem("checkoutPreferences");

                  this.$store.commit("setLoading", true);
                  this.$router.push({ name: "Mis pedidos" });
                  this.$store.commit("cart/removeAllFromCart", null, {
                    root: true,
                  });
                  setTimeout(() => {
                    this.$store.commit("setLoading", false);
                    this.fetchProducts();
                  }, 500);
                }
              });
          }
        } else if (this.paymentType === "card") {
          this.$router.push({ name: "Mis pedidos" });
          this.$store.commit("cart/removeAllFromCart", null, { root: true });
          window.open(responseData.recibo, "_blank");
        }
      } catch (error) {
        console.error(error);
        this.checkoutError = true;
      } finally {
        this.$store.commit("setLoading", false);
        this.fetchProducts();
      }
    },
    calcularDatosFinalesCarrito(cart) {
      let carritoData = {
        subtotal: 0,
        totalDescuento: 0,
        totalImpuestos: 0,
        totalPesoVol: 0,
        countProds: this.cart.length,
      };

      cart.forEach((product) => {
        let precioProd = parseFloat(product.producto.precio);
        let pesoVol = parseFloat(product.producto.peso_volumetrico);
        let porciento = 0;
        let descuentoProd = 0;
        let cantidadProducto = product.qty;
        let descuentoTotal = 0;
        let impuestoTotal = 0;

        // console.log("precioProd: ", precioProd);
        // Nueva forma de calcular precio final con descuento de cada producto
        if (this.customerDiscount) {
          porciento = parseFloat(this.customerDiscount.porcentaje_descuento);
          descuentoProd = precioProd * porciento;
          precioProd = precioProd - descuentoProd;
          descuentoTotal += descuentoProd;
        } else if (product.descuento) {
          let descuentos = product.descuento;
          descuentos.forEach((descuento) => {
            if (cantidadProducto >= descuento.cantidad_compra_minima) {
              porciento = parseFloat(descuento.porcentaje_descuento);
              descuentoProd = precioProd * porciento;
              precioProd = precioProd - descuentoProd;
              descuentoTotal += descuentoProd;
            }
          });
        }
        // Vieja forma de calcular precio final con descuento de cada producto
        // if(product.producto.en_oferta) {
        //   porciento = product.producto.oferta
        //   descuentoProd = precioProd * porciento
        //   precioProd = precioProd - descuentoProd
        //   descuentoTotal = descuentoProd
        // }

        product.producto.impuesto.forEach((impuesto) => {
          impuestoTotal += impuesto.valor_impuesto * precioProd;
        });

        if (cantidadProducto > 1) {
          precioProd = precioProd * cantidadProducto;
          descuentoTotal = descuentoTotal * cantidadProducto;
          impuestoTotal = impuestoTotal * cantidadProducto;
          pesoVol = pesoVol * cantidadProducto;
        }

        carritoData.totalPesoVol += pesoVol;
        carritoData.totalImpuestos += impuestoTotal;
        carritoData.totalDescuento += descuentoTotal;
        carritoData.subtotal += precioProd;
        // console.log("CarritoData: ", carritoData);
        // console.log("precioProd final: ", precioProd);
      });
      return carritoData;
    },
    calcularEnvioCarrito(tipoEnvio) {
      this.envioSeleccionado = tipoEnvio;
      let pesoTotalCarrito = this.finalCartData.totalPesoVol;
      // console.log("peso total: ", pesoTotalCarrito);
      let envioElegido = this.tabuladorEnvios.find(
        (tabulador) =>
          tabulador.tipo_envio.id === tipoEnvio &&
          pesoTotalCarrito >= parseFloat(tabulador.volumetrico_inferior) &&
          pesoTotalCarrito <= parseFloat(tabulador.volumetrico_superior)
      );
      // console.log("precio elegido", envioElegido);
      this.envioCalculado = parseFloat(envioElegido.precio);
    },
    async getImportantData() {
      await Promise.all([this.traerEnvios(), this.traerTabulador()]).then(
        (response) => {
          this.tipoEnvio = response[0].data;
          this.tabuladorEnvios = response[1].data;
          // console.log("tabulador", response);
        }
      );
    },
    traerEnvios() {
      return ApiAuth.get("/checkout/api/tipo-envios/");
    },
    traerTabulador() {
      /*
       * En este método se traen los tabuladores del distribuidor con id 5, esto
       * está harcoded hasta que se implemente el distribuidor que se le asigna al
       * cliente en base a cercanía.
       */
      //let distribuidorId = 1
      return ApiAuth.get(
        `/checkout/api/tabulador-envios/?distribuidor_id=${this.distributorId}`
      );
    },
  },
  created() {
    this.getImportantData();
    if (localStorage.getItem("checkoutPreferences")) {
      this.checkoutPreferences = JSON.parse(
        localStorage.getItem("checkoutPreferences")
      );
      this.envioSeleccionado = this.checkoutPreferences.shippingSelected;
      this.metodoPagoSeleccionado = this.checkoutPreferences.paymentMethodSelected;
      if (this.metodoPagoSeleccionado === 1) {
        this.waitingOxxo = true;
      }
    }
    // console.log("checkoutPreferences ", this.checkoutPreferences);
  },
  async mounted() {
    this.stripe = await loadStripe(config.STRIPE_PUBLISHABLE_KEY);
    /*setTimeout(() => {
        console.log("a ver", this.$refs.personalDataValidation)
        this.$refs.personalDataValidation.checkoutValidation()
      },1000) */
  },
};
</script>

<style>
.btn-selected {
  background-color: #ff3700;
  color: white;
}
.v-btn {
  margin-left: 15px;
}
</style>
